import { AskResponse, CitationType } from '../chat';

export type ParsedAnswer = {
  citations: CitationType[];
  markdownFormatText: string;
};

export function parseAnswer(input: AskResponse): ParsedAnswer {
  if (!input.answer) {
    return {
      citations: [],
      markdownFormatText: '',
    };
  }

  if (!input.citations.length) {
    return {
      citations: [],
      markdownFormatText: input.answer,
    };
  }

  let answerText = input.answer;
  const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

  const lengthDocN = '[doc'.length;

  const filteredCitations = [] as CitationType[];
  let citationReindex = 0;
  citationLinks?.forEach((link) => {
    // Replacing the links/citations with number
    const citationIndex = link.slice(lengthDocN, link.length - 1);
    const citation = JSON.parse(
      JSON.stringify(input.citations[Number(citationIndex) - 1])
    ) as CitationType;
    if (!filteredCitations.find((c) => c.id === citationIndex)) {
      answerText = answerText.replaceAll(link, ` [${citationReindex + 1}] `);
      citation.id = citationIndex; // original doc index to de-dupe
      citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
      filteredCitations.push(citation);
      citationReindex += 1;
    }
  });

  return {
    citations: filteredCitations,
    markdownFormatText: answerText,
  };
}

export function parseAnswerAzure(input: AskResponse): ParsedAnswer {
  let answerText = input.answer;
  const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

  const lengthDocN = '[doc'.length;

  const filteredCitations = [] as CitationType[];
  let citationReindex = 0;

  citationLinks?.forEach((link) => {
    // Replacing the links/citations with number
    const citationIndex = link.slice(lengthDocN, link.length - 1);
    const citation = input.citations[Number(citationIndex) - 1] as any;
    if (!filteredCitations.find((c) => c.id === citationIndex)) {
      answerText = answerText.replaceAll(link, ` [${citationReindex + 1}] `);
      citation.id = citationIndex; // original doc index to de-dupe
      citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
      filteredCitations.push(citation);
      citationReindex += 1;
    }
  });

  return {
    citations: filteredCitations,
    markdownFormatText: answerText,
  };
}

export default parseAnswer;
