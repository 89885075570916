/* eslint-disable no-continue */
/* eslint-disable no-loop-func */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React, { memo, useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { chatGPTContinueConversation, chatGPTCreateConversation } from '../../../../api/chat_gpt';

import useAppToken from '../../../../app/useAppToken';
import AlertSnack from '../../../../components/Alert/AlertSnack';
import M2GlobalContext from '../../../ContextMager/M2GlobalContext';
import Module2Context from '../../Module2Context';
import { ChatContext } from './ChatContext';
import { parseResponseAzureOpenAI, parseResponseGpt3 } from './tools/parseApiResponse';

const extractLayer = (subLayer) => {
  return subLayer.map((element) => element.label);
};

const InputPrompt = ({ init }: { init?: string }) => {
  const { state, dispatch } = useContext(ChatContext);
  const { project } = useContext(Module2Context);
  // const { state: stateSixlayer } = useContext(M2GlobalContext);
  const { appJWT } = useAppToken();

  const { state: rexState, dispatch: rexDispatch } = useContext(M2GlobalContext);
  const { similarityFilter, rexFilter, isearcherFilter } = rexState;

  // console.log('isearcherFilter : ', isearcherFilter?.productLinefilt1);

  function openArray(arr) {
    const r = arr?.map((obj) => obj.value);
    return r.join(',');
  }
  const passedData = {
    // m2 rex filter
    Product_Line:
      rexFilter?.productrexLine?.length !== 0 ? openArray(rexFilter?.productrexLine) : null,
    Platform: rexFilter?.rexPlatform?.length !== 0 ? openArray(rexFilter?.rexPlatform) : null,
    Solution: rexFilter?.solution?.length !== 0 ? openArray(rexFilter?.solution) : null,
    Product: rexFilter?.product?.length !== 0 ? openArray(rexFilter?.product) : null,
    Project: rexFilter?.project?.length !== 0 ? openArray(rexFilter?.project) : null,
  };

  const passedDataPrivate = useMemo(() => {
    return {
      Product_Line:
        isearcherFilter?.productLinefilt1?.length !== 0
          ? openArray(isearcherFilter?.productLinefilt1)
          : null,
      Platform:
        isearcherFilter?.filterPlatformsfilt2?.length !== 0
          ? openArray(isearcherFilter?.filterPlatformsfilt2)
          : null,
      Solution:
        isearcherFilter?.filterSolutionfilt6?.length !== 0
          ? openArray(isearcherFilter?.filterSolutionfilt6)
          : null,
      Product:
        isearcherFilter?.filterProductfilt7?.length !== 0
          ? openArray(isearcherFilter?.filterProductfilt7)
          : null,
      Project:
        isearcherFilter?.filterProjectsfilt3?.length !== 0
          ? openArray(isearcherFilter?.filterProjectsfilt3)
          : null,
      DocumentProfile:
        isearcherFilter?.filterDocumentProfilesfilt4?.length !== 0
          ? openArray(isearcherFilter?.filterDocumentProfilesfilt4)
          : null,
    };
  }, [isearcherFilter]);
  // console.log('passedDataPrivate1 : ', passedDataPrivate);

  // Define the state and ref for passedDataPrivate
  const [passedDataPrivateInt, setPassedDataPrivateInt] = useState(passedDataPrivate);
  const passedDataPrivateRef = useRef(passedDataPrivateInt);

  // Update the ref whenever the state changes
  useEffect(() => {
    passedDataPrivateRef.current = passedDataPrivateInt;
  }, [passedDataPrivateInt]);

  useEffect(() => {
    if (passedDataPrivate) {
      setPassedDataPrivateInt(passedDataPrivate);
    }
  }, [passedDataPrivate]);

  function removeEmpty(passedData: any) {
    const newData = Object.fromEntries(
      Object.entries(passedData).filter(([_, value]) => value != null)
    );
    return newData;
  }

  // console.log(removeEmpty(passedData));

  function transformQueryNew(input) {
    // Step 1: Process each key-value pair in the input object
    const outputClauses = Object.keys(input).map((key) => {
      const value = input[key];
      // Step 2: Split the value by comma if there are multiple values
      const valuesArray = value.split(',').map((val) => val.trim());
      // Step 3: Create output clause
      if (valuesArray.length > 1) {
        return `(${valuesArray.map((val) => `${key} eq '${val}'`).join(' or ')})`;
      }
      return `(${key} eq '${value}')`;
    });

    // Step 4: Join output clauses back together with ' and '
    return outputClauses.join(' and ');
  }

  // console.log(transformQueryNew(removeEmpty(passedData)));

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: 'SET_ERROR',
      payload: '',
    });
  };

  // const buildSixLayer = useMemo(() => {
  //   return {
  //     sixlayer: {
  //       product_lines: extractLayer(stateSixlayer.similarityFilter.dropDown1),
  //       platforms: extractLayer(stateSixlayer.similarityFilter.dropDown2),
  //       solutions: extractLayer(stateSixlayer.similarityFilter.dropDown5),
  //       products: extractLayer(stateSixlayer.similarityFilter.dropDown6),
  //       project_names: extractLayer(stateSixlayer.similarityFilter.dropDown3),
  //     },
  //   };
  // }, [stateSixlayer.similarityFilter]);

  const resetPrompt = () => {
    try {
      if (document.getElementById('chat-textbox')) {
        document.getElementById('chat-textbox').value = '';
        document.getElementById('chat-textbox').focus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleSubmitPrompt = () => {
    // console.log('6 passedDataPrivate : ', passedDataPrivateRef.current);
    const sixlayerFilter = transformQueryNew(
      removeEmpty(state.gptEngine === 'private' ? passedDataPrivateRef.current : passedData)
    );

    const promptText = document.getElementById('chat-textbox').value.trim();
    // validation text  not empty
    if (promptText.length === 0) {
      dispatch({
        type: 'SET_ERROR',
        payload: 'Please enter a valid text',
      });
      return;
    }
    resetPrompt();
    try {
      const abortControllerPost = new AbortController();
      const abortController = new AbortController();
      dispatch({
        type: 'ABORTCONTROLLER_ADD',
        payload: abortControllerPost,
      });
      dispatch({
        type: 'ABORTCONTROLLER_ADD',
        payload: abortController,
      });
      dispatch({
        type: 'LOADING_PROMPT',
        payload: true,
      });
      dispatch({
        type: 'PROMPT_START',
        payload: promptText,
      });
      if (state.gptEngine === 'public') {
        const streamMode = true;

        // new discussion public
        if (!state.discussionId) {
          chatGPTCreateConversation(
            state.gptEngine,
            promptText,
            { stream: streamMode, signal: abortControllerPost.signal },
            project
          )
            .then((responseNewDiscuss) => {
              dispatch({
                type: 'DISCUSSION_INIT_STATE',
                payload: responseNewDiscuss,
              });
              chatGPTContinueConversation(
                state.gptEngine,
                responseNewDiscuss.id,
                promptText,
                {
                  stream: streamMode,
                  token: appJWT.access_token,
                  signal: abortController.signal,
                },
                project
              )
                .then(async (response) => {
                  if (response?.body && streamMode) {
                    if (response.status !== 200) {
                      dispatch({
                        type: 'SET_ERROR',
                        payload: 'An error occurred while streaming',
                      });
                      return;
                    }
                    await parseResponseGpt3(response, dispatch);
                  } else {
                    dispatch({
                      type: 'SUBMIT_PROMPT',
                      payload: response,
                    });
                  }
                })
                .catch((e) => {
                  if (abortController.signal.aborted) return;
                  dispatch({
                    type: 'SET_ERROR',
                    payload: e.message,
                  });
                })
                .finally(() => {
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                });
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e,
              });
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        } else {
          // old discussion
          chatGPTContinueConversation(
            state.gptEngine,
            state.discussionId,
            promptText,
            {
              stream: streamMode,
              token: appJWT.access_token,
              signal: abortController.signal,
            },
            project
          )
            .then(async (response) => {
              if (response?.body && streamMode) {
                if (response.status !== 200) {
                  dispatch({
                    type: 'SET_ERROR',
                    payload: 'An error occurred while streaming',
                  });
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                  return;
                }
                await parseResponseGpt3(response, dispatch);
              } else {
                dispatch({
                  type: 'SUBMIT_PROMPT',
                  payload: response,
                });
              }
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e.message,
              });
            })
            .finally(() => {
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        }
      } else {
        const streamMode = true;
        // new discussion private
        if (!state.discussion?.id) {
          chatGPTCreateConversation(
            state.gptEngine,
            promptText,
            {
              stream: streamMode,
              signal: abortControllerPost.signal,
            },
            project
            // buildSixLayer
          )
            .then((responseNewDiscuss) => {
              dispatch({
                type: 'DISCUSSION_INIT_STATE',
                payload: responseNewDiscuss,
              });
              // common api for rex and private gpt
              chatGPTContinueConversation(
                state.gptEngine,
                responseNewDiscuss.id,
                promptText,
                {
                  stream: streamMode,
                  token: appJWT.access_token,
                  signal: abortController.signal,
                  filters: sixlayerFilter,
                },
                project
              )
                .then(async (response) => {
                  if (response?.body && streamMode) {
                    // await parseResponseGpt4Private(response, dispatch);
                    await parseResponseAzureOpenAI(response, dispatch);
                    dispatch({
                      type: 'LOADING_PROMPT',
                      payload: false,
                    });
                  } else {
                    dispatch({
                      type: 'SUBMIT_PROMPT',
                      payload: response,
                    });
                  }
                })
                .catch((e) => {
                  if (abortController.signal.aborted) return;
                  dispatch({
                    type: 'SET_ERROR',
                    payload: e.message,
                  });
                })
                .finally(() => {
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                });
            })
            .catch((e) => {
              dispatch({
                type: 'SET_ERROR',
                payload: e,
              });
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        } else {
          chatGPTContinueConversation(
            state.gptEngine,
            state.discussionId,
            promptText,
            {
              stream: streamMode,
              token: appJWT.access_token,
              signal: abortController.signal,
              filters: sixlayerFilter,
            },
            project
          )
            .then(async (response) => {
              if (response?.body && streamMode) {
                // await parseResponseGpt4Private(response, dispatch);
                await parseResponseAzureOpenAI(response, dispatch);
                dispatch({
                  type: 'LOADING_PROMPT',
                  payload: false,
                });
              } else {
                dispatch({
                  type: 'SUBMIT_PROMPT',
                  payload: response,
                });
              }
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e.message,
              });
            })
            .finally(() => {
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitPrompt = useCallback(() => _handleSubmitPrompt(), [
    state.discussionId,
    state.gptEngine,
  ]);

  useEffect(() => {
    if (init) {
      document.getElementById('chat-textbox').value = init;
      setTimeout(() => {
        handleSubmitPrompt();
      }, 200);
    }
  }, [init]);

  // Function to format the values of passedDataPrivateInt keys
  const formatPassedDataPrivateIntValues = () => {
    if (passedDataPrivateInt && typeof passedDataPrivateInt === 'object') {
      return Object.entries(passedDataPrivateInt)
        .filter(([key, value]) => value !== null) // Filter out null values
        .map(([key, value]) => (
          <span key={key}>
            <strong>{key}:</strong> {value},
          </span>
        ));
    }
    return '';
  };

  return (
    <Paper
      style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        paddingLeft: 30,
        background: 'transparent',
        paddingRight: 30,
        boxShadow: 'None',
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            border: '1px solid #002A45',
            boxShadow: '1px solid #002A45',
            backgroundColor: state.loadingPrompt ? 'lightgrey' : '',
            // borderRadius: 10,
            padding: 2,
            borderRadius: 23,
            marginBottom: 5,
            backgroundColor: '#F8F8F8',
            // background: '#F8F8F8',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '18px',
          }}
        >
          <TextField
            disabled={state.loadingPrompt}
            multiline
            type="text"
            name="textbox"
            id="chat-textbox"
            style={{
              fontSize: '17px',
              padding: '2px',
              fontWeight: 'bold',
              backgroundColor: '#F8F8F8',
              width: '100%',
              border: 'none',
              borderRadius: '10px',
              borderBottom: 'none',
              maxHeight: '300px', // Limiting the maximum height
              overflowY: 'auto', // Enable vertical scrolling
            }}
            InputProps={{ disableUnderline: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                handleSubmitPrompt();
              }
            }}
            placeholder="Send a message"
          />
          &nbsp;
          <Box>
            <IconButton
              onClick={handleSubmitPrompt}
              style={{
                backgroundColor: '#002a45',
                color: 'white',
                padding: 10,
              }}
              size="small"
              disabled={state.loadingPrompt}
            >
              {state.loadingPrompt ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <SendIcon />
              )}
            </IconButton>
          </Box>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>
            <Typography>Shift+enter for new line</Typography>
          </div>
        </div>
        <Grid>
          <div>
            <div style={{ float: 'right' }}>
              <Typography>{formatPassedDataPrivateIntValues()}</Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      {state.errorMsg && state.errorMsg !== '' && (
        <AlertSnack
          msg={state.errorMsg}
          handleClose={handleCloseSnack}
          severity="error"
          duration={3000}
        />
      )}
    </Paper>
  );
};

export default memo(InputPrompt);
