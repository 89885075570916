import { BACKEND_URL } from '../const';
import { gptEngineType } from '../containers/Module2/NewModule/GPT2/ChatReducer';
import { DiscussionType, DiscussionTypeUpdate } from '../containers/Module2/NewModule/GPT2/chat';
import { Project } from '../react-app-env';
import axios from './axios';

export const chatGPTApiGet = async (
  actionFilter?: string,
  start = 0
): Promise<DiscussionType[]> => {
  try {
    const actionQuery = actionFilter ? `&action=${actionFilter}` : '';
    const response = await axios.get(
      `${BACKEND_URL.service_gpt}/gpt/cache?${actionQuery}&start=${start}`
    );
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const chatGPTTitleApiPut = async (
  id: any,
  body: DiscussionTypeUpdate
): Promise<DiscussionType> => {
  try {
    // eslint-disable-next-line no-param-reassign
    delete body.id;
    const response = await axios.put(`${BACKEND_URL.service_gpt}/gpt/cache/${id}`, body);
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const chatGPTFeedbackApiPut = async (
  id: any,
  response_id: any,
  body: DiscussionTypeUpdate
): Promise<DiscussionType> => {
  try {
    // eslint-disable-next-line no-param-reassign
    delete body.id;
    const response = await axios.put(
      `${BACKEND_URL.service_gpt}/gpt/cache/${id}/feedback/${response_id}`,
      body
    );
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const chatGPTApiDelete = async (id: any): Promise<any> => {
  try {
    const response = await axios.delete(`${BACKEND_URL.service_gpt}/gpt/cache/${id}`);
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const chatGPTCreateConversation = async (
  gpt_engine: gptEngineType,
  inputValue: any,
  options: {
    stream?: boolean;
    token?: any;
    signal?: AbortSignal;
  },
  project?: Project,
  sixlayer?: any
): Promise<DiscussionType> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.service_gpt}/gpt/prompt?gpt_engine=${gpt_engine}&stream=${
        options.stream ? '1' : '0'
      }`,
      {
        prompt: inputValue,
        project: project ? { id: project.id, name: project.name } : undefined,
        sixlayer,
      },
      {
        signal: options.signal,
      }
    );
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const chatGPTContinueConversation = async (
  gpt_engine: gptEngineType,
  id: any,
  inputValue: any,
  options: {
    stream?: boolean;
    token?: any;
    signal?: AbortSignal;
    filters?: string;
  },
  project?: Project
): Promise<any> => {
  try {
    if (options.stream) {
      return await fetch(
        `${BACKEND_URL.service_gpt}/gpt/prompt/${id}?gpt_engine=${gpt_engine}&stream=${
          options.stream ? '1' : '0'
        }`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('appJWT') || '').access_token
            }`,
          },
          body: JSON.stringify({
            prompt: inputValue,
            project: project ? { id: project.id, name: project.name } : undefined,
            ...(options.filters && {
              sixlayer: options.filters && options.filters.length > 0 ? options.filters : undefined,
            }),
          }),
          signal: options.signal,
        }
      );
    }
    const response = await axios.put(
      `${BACKEND_URL.service_gpt}/gpt/prompt/${id}?gpt_engine=${gpt_engine}`,
      {
        prompt: inputValue,
        project: project ? { id: project.id, name: project.name } : undefined,
      },
      { signal: options.signal }
    );
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
