import React from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { styled } from '@mui/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Badge, Tooltip } from '@material-ui/core';

export const textAnalysis = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      padding: '1rem',
      margin: '1rem 1rem 1rem 1rem',
      border: '2px solid #002A45',
      minHeight: '100vh',
    },
    subMain1: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    subHalf1: {
      flexBasis: '50%',
    },
    subMain2: {
      height: '26rem',
      display: 'flex',
      // gap: '20px',
      // flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    subMain2Move: {
      display: 'flex',
      // gap: '20px',
      // flexDirection: 'column',
      padding: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
    subMain2Child1: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    listParent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '81%',
      flexWrap: 'wrap',
      gap: '10px',
    },
    operationListItem: {
      position: 'relative',
      backgroundColor: '#EEF1F9',
      borderRadius: '5rem',
      display: 'flex',
      gap: '0.5rem',
    },
    badge: {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
      width: 'fit-content',
      background: 'rgb(102, 187, 106)',
      color: 'white',
      padding: '0rem 0.5rem',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '25px',
    },

    // Document Analysis css

    docMain: {},
    docMainListParent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      width: '100%',
    },

    docOperationListItem: {
      backgroundColor: '#EEF1F9',
      // maxWidth: '10.99rem',
      borderRadius: '5rem',
      display: 'flex',
      gap: '0.1rem',
    },
    // SimilarityAnalysisStyle: {
    //   paddingTop: -60,
    // },

    // MultiSelect Dropdown

    inputColor: {
      height: '2rem',
      width: '78%',
      border: 'none',
    },
    toggle: {
      '& .MuiToggleButton-root': {
        background: 'white 0% 0% no-repeat padding-box',
        border: '1px solid #001F3C',
        height: '2.5rem',
      },
      '& .Mui-selected': {
        color: 'white',
        backgroundColor: '#002A45',
      },
      '& .css-1x4vipr-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
        backgroundColor: '#002A45',
        color: 'white',
      },
    },
    formControlMulti1: {
      // margin: theme.spacing(1),
      minWidth: 220,
      maxWidth: 220,
    },
    formControlMultiSimi: {
      minWidth: 150,
      maxWidth: 150,
    },
  })
);

export const resultStyle = makeStyles((theme) =>
  createStyles({
    barGraphContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20rem',
      backgroundColor: '#EEF1F9',
      border: '1px solid #002A45',
    },
    barGraphTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '4rem',
    },
    barGraph: {
      overflowX: 'scroll',
      padding: '1rem',
      '&::-webkit-scrollbar': {
        width: '0px',
        height: '0.2rem',
        backgroundColor: 'white',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        borderRradius: '3px',
      },
      '&::-webkit-scrollbar-thumb': {
        outline: 'none',
        backgroundColor: '#002a45',
      },
    },
    barGraph2: {
      padding: '1.1rem',
    },
    ToggleResultContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      boxShadow: 'none',
      '& .MuiPaper-root': {
        backgroundColor: '#FBFBFB',
      },
    },
    ToggleResBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardRoot: {
      flexBasis: '50%',
      border: '1px solid #002A45',
      boxShadow: 'none',
      '& .MuiPaper-root': {
        height: '40rem',
      },
      '& .MuiCardHeader-root': {
        color: '#002A45',
        backgroundColor: '#EEF1F9',
        padding: '1rem',
        paddingLeft: '1rem',
        borderBottom: '1px solid #002A45',
      },
      '& .MuiTypography-h5': {
        fontSize: '1.2rem',
        textAlign: 'center',
        fontWeight: 'bold',
      },
      '& .MuiCardContent-root': {
        backgroundColor: '#EEF1F9',
      },
      '.MuiPaper-rounded': {
        borderRadius: '10x',
      },
      '& .MuiCardHeader-action': {
        marginTop: '0rem',
      },
    },
    cardRoot2: {
      border: '1px solid #002A45',
      boxShadow: 'none',
      '& .MuiPaper-root': {
        height: '40rem',
      },
      '& .MuiCardHeader-root': {
        color: '#002A45',
        backgroundColor: '#EEF1F9',
        padding: '1rem',
        paddingLeft: '1rem',
        borderBottom: '1px solid #002A45',
      },
      '& .MuiTypography-h5': {
        fontSize: '1.2rem',
        textAlign: 'center',
        fontWeight: 'bold',
      },
      '& .MuiCardContent-root': {
        backgroundColor: '#EEF1F9',
      },
      '.MuiPaper-rounded': {
        borderRadius: '10x',
      },
    },
    expertParentList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    expertBargrph: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // border: '1px solid #002A45',
    },
    resultPanel: {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '2px !important',
      backgroundColor: '#EEF1F9',
    },
    pieChart: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black',
      fontSize: '1rem',
    },
  })
);

export const extractEnt = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#EEF1F9',
      border: '1px solid #002A45',
      padding: '1rem 0rem',
    },
    entitesContent: {
      display: 'flex',
      gap: '1rem',
      flexWrap: 'wrap',
      overflowY: 'scroll',
      maxHeight: '70vh',
      padding: '1rem',
    },
    entitiesHead: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0 1rem 1rem 1rem',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      borderBottom: '1px solid #002A45',
    },
  })
);
export default textAnalysis;
export const ListItem2 = withStyles({
  root: {
    borderRadius: '10px',
    textAlign: 'center',
    paddingTop: '4px',
    paddingBottom: '4px',
    backgroundColor: '#EEF1F9',
    // alignitems: 'center',
    // justifyContent: 'center',
    // textAlign: 'center',
    width: 'max-content',
    '&$selected': {
      backgroundColor: '#002A45',
      color: 'white',
      width: 'max-content',
    },
    '&$selected:hover': {
      backgroundColor: '#002A45',
      color: 'white',
      width: 'max-content',
    },
    '&:hover': {
      backgroundColor: '#002A45',
      color: 'white',
      width: 'max-content',
    },
  },
  selected: {},
})(MuiListItem);

export const ListItem = withStyles({
  root: {
    display: 'flex',
    alignitems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: 'max-content',
    '&$selected': {
      backgroundColor: '#DC3223',
      color: 'white',
      width: 'max-content',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    '&$selected:hover': {
      backgroundColor: '#DC3223',
      color: 'white',
      width: 'max-content',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    '&:hover': {
      backgroundColor: '#DC3223',
      color: 'white',
      width: 'max-content',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
  },

  selected: {},
})(MuiListItem);
export const AlstomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#002A45',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#002A45',
    color: 'white',
    maxWidth: 140,
    fontSize: 15,
  },
}));

export const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});
