import { DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { CitationType } from './chat';
import { gptEngineType } from './ChatReducer';
import MarkdownContent from './MarkdownContent';

const useStyles = makeStyles((theme) => ({
  topTitle: {
    background: theme.palette.primary.main,
    color: 'white  !important',
    '&.MuiDialogTitle-root': {
      padding: '6px 12px',
    },
  },
  contentMessage: {
    wordBreak: 'break-word',
  },
}));

type Props = {
  doc: CitationType;
  onCloseCitation: () => void;
  title?: string;
  gptEngine?: gptEngineType;
};

function Citation({ doc, onCloseCitation, title, gptEngine }: Props) {
  const classes = useStyles();

  let content = '';
  if (gptEngine === 'private') {
    const clonedDoc = { ...doc };
    for (const [input, value] of Object.entries(clonedDoc)) {
      // console.log(input, value);
      if (!['index', 'chunk_id', 'title'].includes(input.toLowerCase())) {
        content += `**${input}**  \n ${value}  \n `;
      }
    }
  }

  if (gptEngine === 'rex') {
    const clonedDoc = { ...doc };
    for (const [input, value] of Object.entries(clonedDoc)) {
      // console.log(input, value);
      if (!['index'].includes(input.toLowerCase())) {
        content += `**${input}**  \n ${value}  \n `;
      }
    }
  }

  // console.log(content);

  return (
    <div
      style={{
        maxHeight: '100%',
        minHeight: '100%',
        overflowY: 'auto',
      }}
    >
      <DialogTitle className={classes.topTitle}>
        <IconButton aria-label="close" onClick={onCloseCitation}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
        {title}
      </DialogTitle>
      <DialogContent className={classes.contentMessage}>
        <MarkdownContent content={content} />
      </DialogContent>
    </div>
  );
}

export default Citation;
