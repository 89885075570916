/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useContext } from 'react';
import { Avatar, Button, FormControl, Grid, makeStyles } from '@material-ui/core';
import MultiSelect from 'react-multi-select-component';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Box, IconButton } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {
  iSearcheSixLayerNew,
  textToText,
  textToTextInitialFilter,
  RexFiltersNew,
} from '../../../../api/module2';
import M2GlobalContext from '../../../ContextMager/M2GlobalContext';
import { textAnalysis } from '../../../../components/Filter/textAnalysis';

import m5 from '../../../assets/m5_blue.png';
import m1 from '../../../assets/m1_blue.png';
import m2 from '../../../assets/m2_blue.png';
import m3 from '../../../assets/m3_blue.png';
import m4 from '../../../assets/m4_blue.png';

const useStyles = makeStyles((theme: Theme) => ({
  containerNew: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtonNew: {
    marginLeft: theme.spacing(1),
  },
  multiselect: {
    '& .select-panel': {
      // overflowY: 'scroll',
      maxHeight: '22.5vh',
      overflowX: 'hidden',
    },
    '& .options': {
      overflowY: 'unset',
    },
  },
  formControlMulti: {
    minWidth: 220,
    maxWidth: 220,
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}));

const PrivateGenAFilter = ({ ChatGptFilter }: any) => {
  const classes = textAnalysis();
  const selClasses = useStyles();
  const { state, dispatch } = useContext(M2GlobalContext);
  const { rexFilter, isearcherFilter, similarityFilter } = state;

  const {
    simi_AlstomEntitiesDef,
    simi_AlstomEntities,
    simi_FilterProjectsDef,
    simi_FilterProjects,
    simi_FilterDocumentProfilesDef,
    simi_FilterDocumentProfiles,
    simi_FilterPlatformsDef,
    simi_FilterPlatforms,
    simi_FilterSolution,
    simi_FilterProduct,
    dropDown1,
    dropDown2,
    dropDown3,
    dropDown4,
    dropDown5,
    dropDown6,
    countValue,
    threshold,
  } = similarityFilter;

  const {
    productLineDef,
    productLine,
    filterProjectsDef,
    filterProjects,
    filterDocumentProfilesDef,
    filterDocumentProfiles,
    filterPlatformsDef,
    filterPlatforms,
    filterSolution,
    filterProduct,
    filterCountry,
    artiFact,
    productLinefilt1,
    filterPlatformsfilt2,
    filterProjectsfilt3,
    filterDocumentProfilesfilt4,
    filt5,
    filterSolutionfilt6,
    filterProductfilt7,
    filt8,
    maxResultsValue,
    door,
  } = isearcherFilter;

  const onChangeEntity = (option: any) => {
    dispatch({ type: 'isearchser_productLine_change', payload: option });
    // setFilt1(option);
    const extra = 'change_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      option,
      filterPlatformsfilt2,
      filterSolutionfilt6,
      filterProductfilt7,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };

  const onChangePlatform = (option: any) => {
    dispatch({ type: 'isearchser_Platform_change', payload: option });
    // setFilt2(option);
    const extra = 'change_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      option,
      filterSolutionfilt6,
      filterProductfilt7,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };
  const onChangeSolutionNew = (option: any) => {
    dispatch({ type: 'isearchser_Solution_change', payload: option });
    // setFilt1(option);
    const extra = 'change_Solution';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      filterPlatformsfilt2,
      option,
      filterProductfilt7,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };
  const onChangeProductNew = (option: any) => {
    dispatch({ type: 'isearchser_Product_change', payload: option });
    // setFilt1(option);
    const extra = 'change_Product';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      filterPlatformsfilt2,
      filterSolutionfilt6,
      option,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };

  const onChangeProject = (option: any) => {
    dispatch({ type: 'isearchser_Project_change', payload: option });
    // setFilt3(option);
    const extra = 'change_project';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      filterPlatformsfilt2,
      filterSolutionfilt6,
      filterProductfilt7,
      option,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };

  const onChangeDocumentProfile = (option: any) => {
    dispatch({ type: 'isearchser_DocProfile_change', payload: option });
    // setFilt4(option);
    const extra = 'change_documentProfile';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      filterPlatformsfilt2,
      filterSolutionfilt6,
      filterProductfilt7,
      filterProjectsfilt3,
      option,
      filt8,
      extra,
      1
    );
  };

  function multifilterCall(
    en: any,
    plat: any,
    sol: any,
    prod: any,
    proj: any,
    docprof: any,
    cont: any,
    extra: any,
    m: any
  ) {
    const entityVal: any = [];
    const platformVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];
    const projectVal: any = [];
    const documentProfileVal: any = [];
    const countryVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    plat.forEach((o: any) => {
      platformVal.push(o.value);
    });
    sol.forEach((o: any) => {
      solutionVal.push(o.value);
    });
    prod.forEach((o: any) => {
      productVal.push(o.value);
    });
    proj.forEach((o: any) => {
      projectVal.push(o.value);
    });
    docprof.forEach((o: any) => {
      documentProfileVal.push(o.value);
    });
    cont.forEach((o: any) => {
      countryVal.push(o.value);
    });
    // console.log(m);
    if (m === 1) {
      iSearcheSixLayerNew(
        entityVal,
        platformVal,
        solutionVal,
        productVal,
        projectVal,
        documentProfileVal,
        countryVal
      )
        .then((payload: any) => {
          if (payload) {
            // console.log(payload);
            const entityAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.productLines) {
              const item = payload.filters.productLines[i];
              entityAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_entity') {
            //   setAlstomEntities2(entityAfterFilter);
            // }

            const platformAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.platforms) {
              const item = payload.filters.platforms[i];
              platformAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_platform') {
            //   setFilterPlatforms2(platformAfterFilter);
            // }
            const projectAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.projects) {
              const item = payload.filters.projects[i];
              projectAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_project') {
            //   setFilterProjects2(projectAfterFilter);
            // }
            const documentProfileAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.documentProfiles) {
              const item = payload.filters.documentProfiles[i];
              documentProfileAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const solutionAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.solutions) {
              const item = payload.filters.solutions[i];
              solutionAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const productAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.products) {
              const item = payload.filters.products[i];
              productAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const countryAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.countries) {
              const item = payload.filters.countries[i];
              countryAfterFilter.push({
                label: item,
                value: item,
              });
            }

            switch (extra) {
              case 'change_entity': {
                const obj = {
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_productLine', payload: obj });
                break;
              }

              case 'change_platform': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_platform', payload: obj });
                break;
              }
              case 'change_Solution': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_Solution', payload: obj });
                break;
              }
              case 'change_Product': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_Product', payload: obj });
                break;
              }

              case 'change_project': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_project', payload: obj });
                break;
              }

              case 'change_documentProfile': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_documentProfile', payload: obj });
                break;
              }
              case 'change_Country': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                };
                dispatch({ type: 'noChange_in_Country', payload: obj });
                break;
              }
              default:
                break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }

  // multiselect dropdown custom labels
  // eslint-disable-next-line consistent-return
  const customEntityValue = () => {
    if (!(productLinefilt1.length || dropDown1.length)) {
      return <b style={{ color: '#002A45' }}>Product Line</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customPlatformValue = () => {
    if (!(filterPlatformsfilt2.length || dropDown2.length)) {
      return <b style={{ color: '#002A45' }}>Platform</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customSolutionValue = () => {
    if (!dropDown5.length || !filterSolutionfilt6.length) {
      return <b style={{ color: '#002A45' }}>Solution</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customSolutionValueCPE = () => {
    if (!filterSolutionfilt6.length) {
      return <b style={{ color: '#002A45' }}>Solution</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProductValueCPE = () => {
    if (!filterProductfilt7.length) {
      return <b style={{ color: '#002A45' }}>Product</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customSolutionValueRET = () => {
    if (!filterSolutionfilt6.length) {
      return <b style={{ color: '#002A45' }}>Solution</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProductValueRET = () => {
    if (!filterProductfilt7.length) {
      return <b style={{ color: '#002A45' }}>Product</b>;
    }
  };

  // eslint-disable-next-line consistent-return
  const customProductValue = () => {
    if (!dropDown6.length || !filterProductfilt7.length) {
      return <b style={{ color: '#002A45' }}>Product</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customCountryValue = () => {
    if (!filt8.length) {
      return <b style={{ color: '#002A45' }}>Country</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customCountryValueCPE = () => {
    if (!filt8.length) {
      return <b style={{ color: '#002A45' }}>Country</b>;
    }
  };

  // eslint-disable-next-line consistent-return
  const customProjectValue = () => {
    if (!(filterProjectsfilt3.length || dropDown3.length)) {
      return <b style={{ color: '#002A45' }}>Project</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customDocumentProfileValue = () => {
    if (!(filterDocumentProfilesfilt4.length || dropDown4.length)) {
      return <b style={{ color: '#002A45' }}>Document Profile</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customArtifactValue = () => {
    if (!filt5.length) {
      return <b style={{ color: '#002A45' }}>Artifact</b>;
    }
  };

  // Clearing filers for content search, expert search, people search
  function ClearFilter() {
    dispatch({ type: 'clear_isearch_filter' });
  }

  return (
    <Grid
      style={{
        padding: 5,
        backgroundColor: '#EEF1F9',
        marginTop: 0,
      }}
    >
      <Grid spacing={1} className={selClasses.containerNew}>
        <Grid item xs={6}>
          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={productLine}
                labelledBy="Entities"
                isCreatable={true}
                value={productLinefilt1}
                onChange={onChangeEntity}
                valueRenderer={customEntityValue}
                overrideStrings={{
                  allItemsAreSelected: productLinefilt1
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={filterPlatforms}
                labelledBy="Select"
                isCreatable={true}
                value={filterPlatformsfilt2}
                onChange={onChangePlatform}
                valueRenderer={customPlatformValue}
                overrideStrings={{
                  allItemsAreSelected: filterPlatformsfilt2
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>

          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={filterSolution}
                labelledBy="Select"
                isCreatable={true}
                value={filterSolutionfilt6}
                onChange={onChangeSolutionNew}
                valueRenderer={customSolutionValueRET}
                overrideStrings={{
                  allItemsAreSelected: filterSolutionfilt6
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={filterProduct}
                labelledBy="Select"
                isCreatable={true}
                value={filterProductfilt7}
                onChange={onChangeProductNew}
                valueRenderer={customProductValueRET}
                overrideStrings={{
                  allItemsAreSelected: filterProductfilt7
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>

          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={filterProjects}
                labelledBy="Select"
                isCreatable={true}
                value={filterProjectsfilt3}
                onChange={onChangeProject}
                valueRenderer={customProjectValue}
                overrideStrings={{
                  allItemsAreSelected: filterProjectsfilt3
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={filterDocumentProfiles}
                labelledBy="Select"
                isCreatable={true}
                value={filterDocumentProfilesfilt4}
                onChange={onChangeDocumentProfile}
                valueRenderer={customDocumentProfileValue}
                overrideStrings={{
                  allItemsAreSelected: filterDocumentProfilesfilt4
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ float: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FilterAltOffIcon />}
          onClick={ClearFilter}
          style={{ marginRight: '5px' }}
        >
          Clear Filter
        </Button>
        <br />
      </Grid>
    </Grid>
  );
};

export default PrivateGenAFilter;
